@import '@{variablesLocation}';
@import '/Foundation/Styling/code/thule/global/media-queries.less';

.header-text-button {
  &:not(.bg-color--white):not(.bg-color--black) {
    padding-top: @spacer12;
    padding-bottom: @spacer12;
  }

  .toggle-btn {
    display: none;
  }

  &.showmore {
    .toggle-btn {
      border-bottom: 0.1rem solid @Gray80;
      display: block;

      .text_showmore,
      .text_showless {
        display: none;
      }
    }

    .text-container {
      position: relative;

      &.expanded {
        .text-content {
          height: auto;
        }

        .text_showless {
          display: block;
        }
      }

      &:not(.expanded) {
        .toggle-btn .text_showmore {
          display: block;
        }
        .text-content::after {
          content: '';
          background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), #fff 71%);
          bottom: 0;
          height: 4rem;
          left: 0;
          pointer-events: none;
          position: absolute;
          width: 100%;
          z-index: 1;
        }
      }
    }

    .text-content {
      @line-height: 2.4rem;
      line-height: @line-height;
      color: @Gray90;
      height: calc(@line-height * 12);
      @media (min-width: @screen-lg-min) {
        height: calc(@line-height * 7);
      }
      overflow: hidden;
      position: relative;
    }
  }

  .text {
    color: @Gray90;

    * {
      color: @Gray90;
    }
  }
}

.article-page {
  .header-text-button {
    .alternate-sizing {
      @media (min-width: @screen-md-min) {
        margin-left: 0;
        flex: 0 0 auto;
        width: 75%;
      }

      @media (min-width: @screen-lg-min) {
        margin-left: 8.33333333%;
        flex: 0 0 auto;
        width: 66.66666667%;
      }

      @media (min-width: @screen-xl-min) {
        margin-left: 16.66666667%;
        flex: 0 0 auto;
        width: 50%;
      }
    }
  }
}

.header-text-button.bg-color--black {
  background-color: @Black;
  color: @White;

  * {
    color: @White;
  }
}
